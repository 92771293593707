import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import { list as adProviderList } from '../../../helpers/AdProvider';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import VibeButtonNew from '../../VibeButton/VibeButtonNew';
import CellTooltip from '../CellTypes/CellTooltip';
import TimestampCell from '../CellTypes/TimestampCell';
import FlightStatus from '../../FlightStatus/FlightStatus';
import color from '../../../sass/color.scss';

const tableId = 'table:flights';
const permissionPrefix = 'flight';

class TableFlights extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: 'Select',
      defaultWidth: 125,
      locked: true,
    },
    {
      name: 'Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Status',
      searchAttr: 'status',
      defaultWidth: 170,
      searchable: true,
      sortable: true,
      resizable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Unconfirmed',
          value: 'unconfirmed',
        },
        {
          label: 'Confirming Queued',
          value: 'confirming-queued',
        },
        {
          label: 'Computing Queued',
          value: 'computing-queued',
        },
        {
          label: 'Confirming',
          value: 'confirming',
        },
        {
          label: 'Computing',
          value: 'computing',
        },
      ],
    },
    {
      name: 'Opportunity',
      searchAttr: 'opportunityName',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Opportunity ID',
      searchAttr: 'opportunityId',
      defaultWidth: 225,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
    },
    {
      name: 'Deal ID',
      searchAttr: 'dealId',
      defaultWidth: 225,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyId',
      sortAttr: 'companyName',
      valueAttr: 'companies',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      searchSelector: 'company',
      sortable: false,
    },
    {
      name: 'Order Reference ID',
      searchAttr: 'extReferenceId',
      defaultWidth: 225,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Delivery System',
      searchAttr: 'deliverySystem',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchSelector: 'delivery-system',
      sortable: true,
    },
    {
      name: 'Ad Provider',
      searchAttr: 'adProvider',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...adProviderList.map(item => ({
          label: item.name,
          value: item.value,
        })),
      ],
    },
    {
      name: 'Start Date',
      searchAttr: 'startDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'End Date',
      searchAttr: 'endDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Created By',
      searchAttr: 'createdByUserName',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Created Date',
      searchAttr: 'createdDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: '...',
      defaultWidth: 72,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveFlight', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveFlight', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.aquaForest,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        const response = await API.Flight.deactivate(_id);

        const successText = 'FLIGHT.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = await API.Flight.reactivate({
          _id,
        });

        const successText = 'FLIGHT.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  // parse content for company multiselect
  getCompaniesString = (value) => {
    let tooltipTitle = '';
    value.forEach((option) => {
      tooltipTitle += `${option.companyName}, `;
    });
    return tooltipTitle.slice(0, -2);
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Select': {
        const {
          highlight,
          onSelectFlight,
        } = this.props;

        const rowId = get(row, '_id');
        const selectedId = get(highlight, '[0]._id');

        return (
          <div
            className="cell-content"
            style={{
              textAlign: 'center',
            }}
          >
            <VibeButtonNew
              text="Select"
              color={color.violetVibe}
              disabled={rowId === selectedId}
              onClick={() => {
                onSelectFlight(row);
              }}
            />
          </div>
        );
      }

      case 'Company':
        return (
          <CellTooltip title={this.getCompaniesString(value)}>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.getCompaniesString(value)}
            </div>
          </CellTooltip>
        );

      case 'Status':
        return (
          <CellTooltip title={value}>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
              }}
            >
              <FlightStatus
                status={value}
              />
            </div>
          </CellTooltip>
        );

      case 'Start Date':
      case 'End Date':
      case 'Created Date':
        return (
          <TimestampCell
            time={value}
            format="ddd, MMM DD, YYYY"
          />
        );

      case 'Ad Provider':
        return (
          <div className="cell-content">
            {find(adProviderList, { value }).name}
          </div>
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
    } = this.state;

    return (
      <div className={classNames('Table', 'TableFlights', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableFlights.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
  onSelectFlight: PropTypes.func,
};

TableFlights.defaultProps = {
  ...tablePropsDefault,
  onSelectFlight: () => {},
};

export default withRouter(TableFlights);

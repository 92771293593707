import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  isEmpty,
  uniqueId,
} from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import API from '../../../api';
import Field from '../../Field/Field2';
import AddRemoveField from '../../Field/AddRemoveField';
import VibeButtonNew from '../../VibeButton/VibeButtonNew';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viMoreHorizontal from '../../../icons/viMoreHorizontal';
import color from '../../../sass/color.scss';
import './AdvancedSearch.scss';

function addSearchField({
  name = '',
  value = '',
}) {
  return {
    name,
    value,
    fieldId: uniqueId('search-field-'),
  };
}

function AdvancedSearch({
  className,
  column,
  currentSearch,
  onSearch,
}) {
  const [open, setOpen] = useState(false);
  const [searchFields, setSearchFields] = useState([addSearchField({})]);

  const fields = searchFields.filter(field => !isEmpty(field.name));
  const searchValues = fields.map(field => field.name);

  const onOpen = () => {
    const currentSearchValues = currentSearch.split(',');
    const currentSearchFields = currentSearchValues.map((value) => {
      return addSearchField({
        name: value,
        value: '',
      });
    });

    setSearchFields([
      ...currentSearchFields,
      addSearchField({}),
    ]);

    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onClickSearch = () => {
    onSearch({
      columnName: column.name,
      searchValues,
    });

    onClose();
  };

  const onChangeSelectorField = (data) => {
    const {
      type,
      name: fieldId,
      id,
      value,
    } = data;

    setSearchFields((prevState) => {
      return prevState.map((searchField) => {
        if (searchField.fieldId === fieldId) {
          return {
            ...searchField,
            name: type === 'category' || type === 'banner' || type === 'advertiser'
              // use the value of the item as the search query (company category, company banner)
              ? value
              // use the ID of the item as the search query
              : id,
            value,
          };
        }

        return searchField;
      });
    });
  };

  const onChangeField = (e) => {
    const {
      target: {
        name: fieldId,
        value,
      },
    } = e;

    setSearchFields((prevState) => {
      return prevState.map((searchField) => {
        if (searchField.fieldId === fieldId) {
          return {
            ...searchField,
            name: value,
            value,
          };
        }

        return searchField;
      });
    });
  };

  /**
   * Add a field to include in search
   */
  const onAddField = () => {
    setSearchFields([
      ...searchFields,
      addSearchField({}),
    ]);
  };

  /**
   * Remove a field to include in search
   */
  const onRemoveField = (fieldId) => {
    setSearchFields(searchFields.filter((field) => field.fieldId !== fieldId));
  };

  return (
    <div className={classNames('AdvancedSearch', className)}>
      <VibeIcon
        icon={viMoreHorizontal}
        color={color.manatee}
        hoverColor={color.obsidian}
        size={24}
        tooltip="Advanced Search"
        tooltipProps={{
          placement: 'bottom',
        }}
        onClick={onOpen}
      />

      <Dialog
        className="AdvancedSearchDialog"
        open={open}
        aria-labelledby="advanced-search-dialog-title"
        aria-describedby="advanced-search-dialog-description"
        onClose={onClose}
      >
        <DialogTitle id="advanced-search-dialog-title">
          Advanced Search - {column.label || column.name}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="advanced-search-dialog-description">
            Perform a search that matches any of the criteria below.
          </DialogContentText>

          <div className="fields">
            {searchFields.map((searchField, index) => {
              const isLast = index === (searchFields.length - 1);

              if (get(column, 'dropdownItems.length', 0) > 0) {
                return (
                  <AddRemoveField
                    key={searchField.fieldId}
                    id={searchField.fieldId}
                    allowAdd={isLast}
                    allowRemove={searchFields.length > 1}
                    onAdd={onAddField}
                    onRemove={onRemoveField}
                  >
                    <Field
                      type="select"
                      name={searchField.fieldId}
                      value={searchField.value}
                      options={column.dropdownItems}
                      onChange={onChangeField}
                    />
                  </AddRemoveField>
                );
              }

              if (column.searchSelector === 'company') {
                return (
                  <AddRemoveField
                    key={searchField.fieldId}
                    id={searchField.fieldId}
                    allowAdd={isLast}
                    allowRemove={searchFields.length > 1}
                    onAdd={onAddField}
                    onRemove={onRemoveField}
                  >
                    <Field
                      type="company"
                      name={searchField.fieldId}
                      value={searchField.value}
                      companyProps={{
                        companyId: searchField.name,
                        inputStyle: {
                          width: 350,
                        },
                      }}
                      multipleValues
                      onChange={onChangeSelectorField}
                    />
                  </AddRemoveField>
                );
              }

              if (column.searchSelector === 'dma') {
                return (
                  <AddRemoveField
                    key={searchField.fieldId}
                    id={searchField.fieldId}
                    allowAdd={isLast}
                    allowRemove={searchFields.length > 1}
                    onAdd={onAddField}
                    onRemove={onRemoveField}
                  >
                    <Field
                      type="dma"
                      name={searchField.fieldId}
                      value={searchField.value}
                      dmaProps={{
                        dmaId: searchField.name,
                        inputStyle: {
                          width: 350,
                        },
                      }}
                      multipleValues
                      onChange={onChangeSelectorField}
                    />
                  </AddRemoveField>
                );
              }

              if (column.searchSelector === 'company-category') {
                return (
                  <AddRemoveField
                    key={searchField.fieldId}
                    id={searchField.fieldId}
                    allowAdd={isLast}
                    allowRemove={searchFields.length > 1}
                    onAdd={onAddField}
                    onRemove={onRemoveField}
                  >
                    <Field
                      type="dropdown"
                      name={searchField.fieldId}
                      value={searchField.value}
                      dropdownProps={{
                        type: 'category',
                        attr: 'value',
                        fetch: API.Company.Category.list,
                        create: API.Company.Category.create,
                        // canCreate: user.can('company_category.create'),
                        // successMessage: 'COMPANYCATEGORY.CREATED',
                        style: {
                          width: 350,
                        },
                      }}
                      // multipleValues
                      onChange={onChangeSelectorField}
                    />
                  </AddRemoveField>
                );
              }

              if (column.searchSelector === 'advertiser') {
                return (
                  <AddRemoveField
                    key={searchField.fieldId}
                    id={searchField.fieldId}
                    allowAdd={isLast}
                    allowRemove={searchFields.length > 1}
                    onAdd={onAddField}
                    onRemove={onRemoveField}
                  >
                    <Field
                      type="dropdown"
                      name={searchField.fieldId}
                      value={searchField.value}
                      dropdownProps={{
                        type: 'advertiser',
                        attr: 'name',
                        fetch: API.Advertiser.list,
                        create: API.Advertiser.create,
                        // canCreate: user.can('advertiser.create'),
                        // successMessage: 'ADVERTISER.CREATED',
                        style: {
                          width: 350,
                        },
                      }}
                      // multipleValues
                      onChange={onChangeSelectorField}
                    />
                  </AddRemoveField>
                );
              }

              // standard text input field
              return (
                <AddRemoveField
                  key={searchField.fieldId}
                  id={searchField.fieldId}
                  allowAdd={isLast}
                  allowRemove={searchFields.length > 1}
                  onAdd={onAddField}
                  onRemove={onRemoveField}
                >
                  <Field
                    type="text"
                    name={searchField.fieldId}
                    placeholder="Search..."
                    value={searchField.value}
                    onChange={onChangeField}
                  />
                </AddRemoveField>
              );
            })}
          </div>
        </DialogContent>

        <DialogActions>
          <VibeButtonNew
            text="Cancel"
            variant="outlined"
            color={color.manatee}
            onClick={onClose}
          />

          <VibeButtonNew
            text={currentSearch && searchValues.length <= 0
              ? 'Clear Search'
              : 'Search'}
            color={color.violetVibe}
            onClick={onClickSearch}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

AdvancedSearch.propTypes = {
  className: PropTypes.string,
  /** Column data */
  column: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  /** Current search from the user */
  currentSearch: PropTypes.string,
  /** When the user clicks search */
  onSearch: PropTypes.func,
};

AdvancedSearch.defaultProps = {
  className: '',
  currentSearch: '',
  onSearch: () => {},
};

export default AdvancedSearch;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
  find,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  FlightSummaryModel,
  NavigationHelper,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  VibeTooltip,
  VibeButtonNew,
  VibeAlert,
  VibeIcon,
  viClose,
  viCopy,
  color,
} from 'vibeguide';
import Information from './Information';
import SegmentCalculator from './SegmentCalculator';
import AtAGlance from './AtAGlance';
import SegmentLocations from './SegmentLocations';
import SegmentFlights from './SegmentFlights';
import './SegmentDetails.scss';
import SegmentDateTime from './SegmentDateTime';

function SegmentDetails({
  className,
  isNew,
  routeId,
  segment,
  flight,
  flightSummary,
  user,
  socket,
  setPanel,
  queueToast,
  onUpdate,
}) {
  const [scenario, setScenario] = useState({
    _id: null,
    flightId: null,
    flightSummary: new FlightSummaryModel(),
  });
  const history = useNavigate();
  const exceedsLocations = user.exceedsLocations(segment.locationSpec);

  /**
   * Get Flight Summary
   */
  const getFlightSummary = async () => {
    const flightSummaryResponse = await API.Flight.summary(scenario.flightId);

    setScenario({
      ...scenario,
      flightSummary: new FlightSummaryModel(flightSummaryResponse),
    });
  };

  /**
   * Get the warning message for the locations assigned (if disabled)
   */
  const getLocationWarning = () => {
    const warning = [];

    if (!exceedsLocations) {
      warning.push('You do not have access to all the locations assigned to this object');
    }

    if (!segment.mediaFormat) {
      warning.push('You must choose a media format before selecting locations');
    }

    return warning.join(' ');
  };

  const getFlightWarning = () => {
    const warning = [];

    if (!segment.startDate || !segment.endDate) {
      warning.push('Please add a start and end date to select a flight');
    }

    return warning.join(' ');
  };

  const onClose = () => {
    setPanel({
      show: false,
    });
  };

  const onClickLocations = () => {
    // only allow location changes when if the flight is uconfirmed (or no flight)
    const allowChanges = (
      !flight._id
      || (flight._id && flight.status === 'unconfirmed' && flight.active)
    ) && (
      ((segment._id && user.can('segment.modify'))
      || (!segment._id && user.can('segment.create')))
    );

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <SegmentLocations
            locations={segment.locationSpec}
            locationsData={segment.locationsData}
            mediaFormat={segment.mediaFormat}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  const onClickFlight = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <SegmentFlights
            flightId={segment.flightId}
            segmentStartDate={segment.startDate}
            segmentEndDate={segment.endDate}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  const onSave = async () => {
    // create a segment scenario when no flight ID is selected
    const isScenario = !segment.flightId;
    const qs = NavigationHelper.getParams();

    const data = {
      name: segment.name,
      locations: segment.locations,
      mediaFormat: segment.mediaFormat,
      spotType: segment.spotType,
      spotSelectionStrategy: segment.spotSelectionStrategy,
      allocateRemainder: segment.allocateRemainder,
      allowBackToBackAdDelivery: segment.allowBackToBackAdDelivery,
      startDate: segment.startDate,
      endDate: segment.endDate,
      dayParts: segment.dayParts,
      daysOfWeek: segment.daysOfWeek,
      segmentType: segment.segmentType,
    };

    // If adProvider !== vibenomics, then remove any leftover empty
    if (flight.adProvider !== 'vibenomics') {
      data.dayParts = segment.dayParts.map(daypart => {
        if (daypart.creatives && daypart.creatives.length === 0) {
          const daypartWithoutCreative = { ...daypart };
          delete daypartWithoutCreative.creatives;
          return daypartWithoutCreative;
        }

        return daypart;
      });
    }

    if (segment._id && qs.type !== 'new') {
      // editing a segment
      data._id = segment._id;
    }

    // only include CPM, Budget, Impressions if a value is provided
    if (segment.cpm > 0 && segment.segmentType !== 'added-value') {
      data.cpm = segment.cpm;
    }

    if (segment.budget > 0 && segment.segmentType !== 'added-value') {
      data.budget = segment.budget;
    }

    if (segment.spotSelectionStrategy !== 'spots-per-hour' && segment.frequencyCapped) {
      data.frequencyCapped = segment.frequencyCapped;
      data.frequencyCap = parseInt(segment.frequencyCap, 10);
    } else {
      data.frequencyCapped = false;
      data.frequencyCap = 0;
    }

    // When the calculator is locked, assuming all 3 fields are filled in, do not send impressions
    // unless the segment type is added-value then impressions are required
    if ((segment.segmentType === 'added-value' && segment.impressions > 0)
      || (segment.segmentType === 'standard' && segment.impressions > 0 && !segment.lockCalculator)
    ) {
      data.impressions = segment.impressions;
    }

    if (segment.spotSelectionStrategy === 'spots-per-hour') {
      data.spotsPerHour = segment.spotsPerHour;
    }

    // a flight is assigned to the segment
    if (!isScenario
      && qs.type !== 'new'
      && routeId !== segment.flightId
    ) {
      data.newFlightId = segment.flightId;
    }

    const createFunc = data => {
      return isScenario
        ? API.Flight.Segment.createScenario(data)
        : API.Flight.Segment.create({ ...data, flightId: segment.flightId });
    };

    const successResponseType = isScenario
      ? 'FLIGHTSCENARIO.CREATED'
      : 'FLIGHTSEGMENT.CREATED';

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = segment._id && qs.type !== 'new'
        ? await API.Flight.Segment.update({ ...data, flightId: routeId })
        : await createFunc(data);

      const segmentId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = data._id
        ? responseType === 'FLIGHTSEGMENT.UPDATED'
        : responseType === successResponseType;

      if (success) {
        // Successfully saved the segment
        const saveMessage = data._id
          ? 'Segment Updated!'
          : 'Segment Created!';

        // get the computed value
        const computedCampaignTriadValue = get(response, '[0].data.computedCampaignTriadValue');
        const computedValue = computedCampaignTriadValue
          ? get(response, `[0].data.${computedCampaignTriadValue}`)
          : null;

        if (computedValue) {
          // update the segment with the computed value
          onUpdate({
            [computedCampaignTriadValue]: computedValue,
            // lock the calculator so subsequent saves won't have an error
            lockCalculator: true,
          });
        }

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSaveSegment'));

        // do not redirect when the segment is only a scenario
        if (!isScenario) {
          const redirectUrl = NavigationHelper.updateParams({
            segmentId,
            flightId: null,
            type: null,
          }, {
            pathname: `/ad-ops/flight/${get(response, '[0].data.flightId', null)}`,
          });

          history(redirectUrl);
        } else {
          // segment is a scenario, need to listen for socket events
          setScenario({
            ...scenario,
            _id: get(response, '[0].documentId', null),
            flightId: get(response, '[0].data.flightId', null),
          });
        }
      } else {
        console.error('Error saving segment to API', response);

        queueToast({
          type: 'error',
          title: 'Error Saving Segment',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error saving segment', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Segment',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  const onClickCalculate = () => {
    onSave();
  };

  /**
   * Listen for socket events
   * Only use for scenario's. SegmentSidebar handles regular segment events
   */
  const onApiEvent = (e) => {
    switch (e.type) {
      case 'FLIGHT.COMPUTED':
      case 'FLIGHT.RECOVERED': {
        if (scenario.flightId) {
          getFlightSummary();
        }
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  /**
   * Check disabling the calculate button
   */
  const checkDisableCalculate = () => {
    if (!segment.name
      || !segment.spotType
      || !segment.spotSelectionStrategy
      || (segment.spotSelectionStrategy === 'spots-per-hour'
        && (!segment.spotsPerHour && !segment.budget))
      || !segment.startDate
      || !segment.endDate
      || segment.dayParts.find(dayPart => dayPart.startTime === null)
      || segment.dayParts.find(dayPart => dayPart.endTime === null)
      || segment.daysOfWeek.length <= 0
      || segment.locationCount <= 0
    ) {
      return true;
    }

    if (segment.spotSelectionStrategy !== 'spots-per-hour') {
      // only allow one field in the "calculator" section to be empty
      let unsetFieldCount = 0;

      if (segment.cpm <= 0) {
        unsetFieldCount += 1;
      }

      if (segment.budget <= 0) {
        unsetFieldCount += 1;
      }

      if (segment.impressions <= 0) {
        unsetFieldCount += 1;
      }

      if ((unsetFieldCount > 0 && segment.segmentType === 'added-value')) {
        return false;
      }

      /**
       * more than 1 unset field
       * or all fields set and calculator is unlocked
       */
      if (unsetFieldCount > 1
        || (unsetFieldCount <= 0 && !segment.lockCalculator)
      ) {
        // require 2 fields to be filled in
        // more than one field in the "calculator" section is empty
        return true;
      }
    }

    return false;
  };

  /**
   * Subscribe to the flight if calculating a scenario
   */
  useEffect(() => {
    if (scenario.flightId) {
      socket.emit('subscribe', {
        topic: 'flight',
        id: scenario.flightId,
      });
    }

    // when component unmounts
    return () => {
      if (scenario.flightId) {
        socket.emit('unsubscribe', {
          topic: 'flight',
          id: scenario.flightId,
        });
      }
    };
  }, [scenario.flightId]);

  /**
   * When connected to the socket, listen for flight events
   */
  useEffect(() => {
    if (socket.connected) {
      socket.off('VAPI_EVENT', onApiEvent);
      socket.on('VAPI_EVENT', onApiEvent);
    }

    // when component unmounts
    return () => {
      socket.off('VAPI_EVENT', onApiEvent);
    };
  }, [socket.connected, scenario.flightId]);

  /**
   * When it's a new segment, allow duplicate ads back 2 back
   */
  useEffect(() => {
    if (isNew) {
      onUpdate({
        allowBackToBackAdDelivery: true,
      });
    }
  }, []);

  const segmentSummary = !scenario.flightId
    // segment summary from a regular flight
    ? find(flightSummary.segments, { _id: segment._id }) || {}
    // segment summary from a scenario
    : find(scenario.flightSummary.segments, { _id: scenario._id }) || {};

  const disableCalculate = checkDisableCalculate();

  // do not allow saving without a flight assignment
  const disableSave = disableCalculate
    || !segment.flightId;

  const disableInput = (flight._id && flight.status !== 'unconfirmed')
    || (flight._id && !flight.active)
    || !exceedsLocations
    || (segment._id && !user.can('segment.modify'))
    || (!segment._id && !user.can('segment.create'));

  const canPartMultiple = flight.adProvider === '' || flight.adProvider === 'vibenomics';
  const disableMedia = !flight._id
    || (flight.adProvider !== '' && flight.adProvider !== 'vibenomics');

  return (
    <SidePanelContainer className={classNames('SegmentDetails', className)}>
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        {segment._id ? (
          <div className="flex-horizontal">
            {get(segmentSummary, 'warnings.length', 0) > 0 && (
              <VibeAlert
                style={{
                  marginRight: 8,
                  fontSize: 12,
                }}
                severity="warning"
                alerts={segmentSummary.warnings || []}
                size={24}
                iconOnly
              />
            )}

            <div className="title">
              <VibeTooltip title={segment.name}>
                <span>
                  {segment.name}
                </span>
              </VibeTooltip>
            </div>
          </div>
        ) : (
          <div className="title">
            New Segment
          </div>
        )}
      </SidePanelHeader>

      <SidePanelContent>
        <Information
          name={segment.name}
          mediaFormat={segment.mediaFormat}
          locationSpec={segment.locationSpec}
          spotType={segment.spotType}
          spotSelectionStrategy={segment.spotSelectionStrategy}
          allocateRemainder={segment.allocateRemainder}
          segmentType={segment.segmentType}
          startDate={segment.startDate}
          endDate={segment.endDate}
          startTime={segment.startTime}
          endTime={segment.endTime}
          daysOfWeek={segment.daysOfWeek}
          frequencyCap={segment.frequencyCap}
          frequencyCapped={segment.frequencyCapped}
          allowBackToBackAdDelivery={segment.allowBackToBackAdDelivery || false}
          disableInput={disableInput}
          onUpdate={onUpdate}
        />

        <SegmentCalculator
          cpm={segment.cpm}
          budget={segment.budget}
          impressions={segment.impressions}
          spotsPerHour={segment.spotsPerHour}
          segmentType={segment.segmentType}
          locked={segment.lockCalculator}
          spotSelectionStrategy={segment.spotSelectionStrategy}
          disableInput={disableInput}
          onUpdate={onUpdate}
        />

        <Assignments
          items={[{
            label: 'Locations Assigned',
            count: segment.locationCount,
            required: true,
            // media format must be selected (and the objects location scope doesn't exceed the users)
            disabled: !exceedsLocations || !segment.mediaFormat,
            warning: getLocationWarning(),
            onClick: exceedsLocations && segment.mediaFormat
              ? onClickLocations
              : null,
          },
          {
            label: 'Flight',
            count: flight.name || 'Choose a Flight...',
            tooltip: flight.name,
            required: true,
            disabled: !exceedsLocations
              || disableInput
              || !segment.startDate
              || !segment.endDate,
            warning: getFlightWarning(),
            // only show flight table selector if the user can modify the segment
            // and the selected locations don't exceed their location scope
            // and the segment has start & end dates
            onClick: exceedsLocations
              && !disableInput
              && segment.startDate
              && segment.endDate
              ? onClickFlight
              : null,
          }]}
        />

        <SegmentDateTime
          advertiserId={flight.advertiserId}
          segmentLocations={segment.locations}
          startDate={segment.startDate}
          endDate={segment.endDate}
          daysOfWeek={segment.daysOfWeek}
          dayParts={segment.dayParts}
          canPartMultiple={canPartMultiple}
          disableMedia={disableMedia}
          onUpdate={onUpdate}
        />

        {/* Show at a glance with regular flight summary data or scenario summary data */}
        {((segment._id && flight.active) || scenario._id) && (
          <AtAGlance
            segmentSummary={segmentSummary}
          />
        )}
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButtonNew
          text="Save Segment"
          style={{
            marginRight: 12,
          }}
          color={color.violetVibe}
          // loadingEvent="onSaveSegment"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {!segment._id && (
          <VibeButtonNew
            text="Calculate"
            color={color.violetVibe}
            tooltip={(
              <div>
                Run to get detailed information about how this segment would perform based on the current configuration.

                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  The &quot;At a Glance&quot; section will update automatically when complete.
                </div>
              </div>
            )}
            disabled={disableCalculate || disableInput}
            onClick={onClickCalculate}
          />
        )}

        {segment._id && user.can('segment.create') && (
          <VibeIcon
            icon={viCopy}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: color.violetVibe,
            }}
            color={color.violetVibe}
            size={16}
            tooltip="Duplicate"
            link={NavigationHelper.updateParams({
              type: 'new',
            })}
          />
        )}
      </SidePanelFooter>
    </SidePanelContainer>
  );
}

SegmentDetails.propTypes = {
  className: PropTypes.string,
  isNew: PropTypes.bool,
  /** Flight or Order ID from the Route */
  routeId: PropTypes.string,
  segment: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  flight: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  flightSummary: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onUpdate: PropTypes.func,
};

SegmentDetails.defaultProps = {
  className: '',
  isNew: false,
  routeId: '',
  segment: {},
  flight: {},
  flightSummary: {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    socket: state.socket.connection,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentDetails);
